<template>
    <v-app>
        <v-app-bar app color="transparent" dark height="125" elevation="0" class="pa-0">
            <div
                id="appBarContent"
                class="appBar"
                style="width: 100%; max-width: 100vw; display: grid; grid-template: 'e e e' 100% / 20% 20% 60%; backdrop-filter: Blur(5px); transition: 200ms background-color"
                :class="[!scrolled ? '' : '', $vuetify.breakpoint.smAndDown ? 'px-3' : 'px-15']">
                <!--  <v-btn
                    style="justify-self: start; align-self: center"
                    :class="$vuetify.breakpoint.smAndDown ? 'text-caption font-weight-light' : ''"
                    class="vtext"
                    text
                    >Coming soon</v-btn
                > -->
                <div style="justify-self: start; align-self: center">
                    <a href="/">
                        <svg xmlns="http://www.w3.org/2000/svg" width="77.654" height="77.654" viewBox="0 0 77.654 77.654" class="mt-1">
                            <g id="Groupe_2" data-name="Groupe 2" transform="translate(-166.184 -2.322)">
                                <g id="Groupe_1" data-name="Groupe 1" transform="translate(166.184 2.322)">
                                    <path
                                        id="path_1"
                                        data-name="Tracé 1"
                                        d="M205.011,79.976a38.827,38.827,0,1,1,38.827-38.827A38.871,38.871,0,0,1,205.011,79.976Zm0-72.623a33.8,33.8,0,1,0,33.8,33.8A33.834,33.834,0,0,0,205.011,7.353Z"
                                        transform="translate(-166.184 -2.322)"
                                        fill="#fff"></path>
                                </g>
                                <path
                                    id="path_2"
                                    data-name="Tracé 2"
                                    d="M210.034,63.591a6.4,6.4,0,0,1,6.157-4.25c3.972,0,7.07,2.661,7.07,6.712V79.836c0,1.628.715,2.383,2.026,2.383.913,0,1.311-.556,1.668-1.669l5.481-16.96a6.348,6.348,0,0,1,6.157-4.25c3.972,0,7.07,2.661,7.07,6.712V86.787h-5.084V66.53c0-1.589-.715-2.383-2.066-2.383-.874,0-1.271.635-1.628,1.747l-5.561,16.921a6.159,6.159,0,0,1-6.116,4.29c-3.932,0-7.03-2.661-7.03-6.713V66.53c0-1.589-.715-2.383-2.065-2.383-.874,0-1.231.635-1.629,1.747l-7.308,20.893h-5.4Z"
                                    transform="translate(-20.019 -32.074)"
                                    fill="#fff"></path>
                            </g>
                        </svg>
                    </a>
                </div>
                <div style="place-self: center">
                    <div :class="$vuetify.breakpoint.smAndDown ? 'text-caption' : 'text-h4' + ' font-weight-light'" class="vtext_2"></div>
                </div>
                <div v-if="!$vuetify.breakpoint.smAndDown" style="justify-self: end; align-self: center">
                    <v-btn href="/expertises" :class="$vuetify.breakpoint.smAndDown ? 'text-caption font-weight-light' : ''" class="vtext" text>
                        <span class="mr-2">Expertises</span>
                    </v-btn>
                    <v-btn href="/about" :class="$vuetify.breakpoint.smAndDown ? 'text-caption font-weight-light' : ''" class="vtext" text>
                        <span class="mr-2">À propos</span>
                    </v-btn>
                    <v-btn href="/realisations" :class="$vuetify.breakpoint.smAndDown ? 'text-caption font-weight-light' : ''" class="vtext" text>
                        <span class="mr-2">Réalisations</span>
                    </v-btn>
                    <v-btn href="/apply" :class="$vuetify.breakpoint.smAndDown ? 'text-caption font-weight-light' : ''" class="vtext" text>
                        <span class="mr-2">Carrière</span>
                    </v-btn>
                    <v-btn :class="$vuetify.breakpoint.smAndDown ? 'text-caption font-weight-light' : ''" href="/contact" class="vtext" text>
                        <span class="mr-2">Contact</span>
                    </v-btn>
                </div>
                <v-btn v-if="$vuetify.breakpoint.smAndDown" @click="menu = !menu" icon style="justify-self: end; align-self: center"><v-icon large>mdi-menu</v-icon></v-btn>
            </div>
        </v-app-bar>

        <v-navigation-drawer app temporary height="fit-content" color="transparent" width="fit-content" right fixed floating v-model="menu" style="top: 110px; right: 10px">
            <v-card dark class="px-3 py-7 menu-card" width="fit-content" max-width="80vw" max-height="500">
                <v-btn href="/expertises" class="ma-3" :class="$vuetify.breakpoint.smAndDown ? 'text-caption font-weight-light' : ''" text>
                    <span class="mr-2">Expertises</span>
                </v-btn>
                <v-btn href="/about" class="ma-3" :class="$vuetify.breakpoint.smAndDown ? 'text-caption font-weight-light' : ''" text>
                    <span class="mr-2">À propos</span>
                </v-btn>
                <v-btn href="/realisations" class="ma-3" :class="$vuetify.breakpoint.smAndDown ? 'text-caption font-weight-light' : ''" text>
                    <span class="mr-2">Réalisations</span>
                </v-btn>
                <v-btn href="/apply" class="ma-3" :class="$vuetify.breakpoint.smAndDown ? 'text-caption font-weight-light' : ''" text>
                    <span class="mr-2">Carrière</span>
                </v-btn>
                <v-btn class="ma-3" :class="$vuetify.breakpoint.smAndDown ? 'text-caption font-weight-light' : ''" href="/contact" text>
                    <span class="mr-2">Contact</span>
                </v-btn>
            </v-card>
        </v-navigation-drawer>
        <v-main class="scrollDist" :style="loading ? 'padding: 0px; overflow: hidden; max-width: 100vw' : 'padding: 0px; overflow-x: hidden; max-width: 100vw'">
            <v-fade-transition>
                <div v-if="loading" class="loader">
                    <div v-html="loader" class="loaderi"></div>
                    <div v-if="!endloading" class="loadingio-spinner-ripple-64cwf99hv1r">
                        <div class="ldio-ossuqae55ee">
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </div>
            </v-fade-transition>
            <v-dialog v-model="credit" max-width="450">
                <v-card>
                    <v-card-title class="primary white--text">Copyright Disclaimer</v-card-title>
                    <v-card-text class="pa-3" style="overflow-y: auto; max-height: 70vh">
                        <p>
                            This Terms and Conditions Agreement (the "Agreement") governs your use of the websites, In addition to the terms set forth in this Agreement, certain Contents have additional terms and conditions, whichcan be
                            located thurther bellow.
                        </p>
                        <p>
                            We own or license all copyright rights in the text, user interface, and other content provided on the Services, and the selection, coordination, and arrangement of such content. Except as expressly provided in
                            this Agreement, you are prohibited from copying, reproducing, modifying, distributing, displaying, performing or transmitting any of the contents of the Services for any purposes, and nothing otherwise stated or
                            implied in the Services confers on you any license or right to do so.
                        </p>
                        <p>
                            You may use the Services and the contents contained in the Services solely for your own individual non-commercial and informational purposes only. Any other use, including for any commercial purposes, is strictly
                            prohibited without our express prior written consent. Systematic retrieval of data or other content from the Services, whether to create or compile, directly or indirectly, a collection, compilation, database or
                            directory, is prohibited absent our express prior written consent.
                        </p>

                        <p>Our website uses images which are the intellectual property of their respective owners.</p>
                        <p>All rights and credit go directly to their rightful owners. No copyright infringement intended</p>
                        <ul>
                            <li>Wavebreak Media LTD - <a href="https://wavebreakmedia.com" target="_blank">wavebreakmedia.com</a></li>
                            <li>Rawpixel LTD - <a href="https://rawpixel.com" target="_blank">rawpixel.com</a></li>
                            <li><a href="https://www.shutterstock.com" target="_blank">www.shutterstock.com</a></li>
                            <li><a href="https://pexels.com" target="_blank">pexels.com</a></li>
                        </ul>
                    </v-card-text>
                    <v-btn text block @click="credit = false">Agree and close</v-btn>
                </v-card>
            </v-dialog>
            <router-view @loaded="mount()" @credit="credit = true" @home="dynamicBar = true" @expertise="dynamicBar = false" />
        </v-main>
    </v-app>
</template>

<script>
import gsap from 'gsap';

import ScrollTrigger from 'gsap/ScrollTrigger';
import ScrollTo from 'gsap/ScrollToPlugin';

var intro = gsap.timeline();
gsap.registerPlugin(ScrollTrigger, ScrollTo);

export default {
    name: 'App',

    mounted() {
        if (this.$route.query.p) {
            window.localStorage.setItem('referer', String(this.$route.query.p));
            console.log('ext-lnk');
            let query = Object.assign({}, this.$route.query);
            if (Object.keys(query).length !== 0) {
                delete query.p;
                this.$router.replace({ query });
            }
        }
    },

    methods: {
        context(e) {
            e.preventDefault();
            console.log('Inspect Source');
        },
        async mount() {
            //console.log('loaded');

            gsap.timeline({
                onStart: () => {
                    this.scrolled = true;
                },
                scrollTrigger: {
                    trigger: '.scrollDist',
                    start: '0% 0%',
                    end: '20% 20%',
                    scrub: 1,
                },
            })
                .fromTo('.vtext', { color: '#ffffff', ease: 'power3.inOut' }, { color: '#ffffff', ease: 'power3.inOut' }, 0)
                .fromTo('#path_1, #path_2', { fill: '#ffffff', ease: 'power3.inOut' }, { fill: '#ffffff', ease: 'power3.inOut' }, 0)

                .fromTo(
                    '#appBarContent',
                    {
                        height: '100px',
                        translateY: 0,
                        backgroundColor: '#00000033',

                        ease: 'power4.out',
                    },
                    {
                        height: '100px',
                        translateY: 0,
                        backgroundColor: '#00000077',

                        ease: 'power4.out',
                    },
                    0
                );
            gsap.timeline({
                scrollTrigger: {
                    trigger: '.scrollDist',
                    start: '20% 20%',
                    end: '100% 100%',
                    scrub: 1,
                },
            }).fromTo(
                '#appBarContent',
                {
                    height: '100px',
                    translateY: 0,
                    backgroundColor: '#00000077',

                    ease: 'power4.out',
                },
                {
                    height: '100px',
                    translateY: 0,
                    backgroundColor: '#00000099',

                    ease: 'power4.out',
                },
                0
            );
            gsap.timeline({
                scrollTrigger: {
                    trigger: '.scrollDist',
                    start: '90% 90%',
                    end: '100% 100%',
                    scrub: 1,
                },
            })

                .fromTo('.vtext', { color: '#ffffff', ease: 'power3.inOut' }, { color: '#ffffff', ease: 'power3.inOut' }, 0)
                .fromTo('#path_1, #path_2', { fill: '#ffffff', ease: 'power3.inOut' }, { fill: '#ffffff', ease: 'power3.inOut' }, 0);

            gsap.set('.vtext', { color: '#ffffff' });
            gsap.set('#path_1, #path_2', { fill: '#ffffff' });
            this.prog();
        },
        async prog() {
            intro.to('.loaderi', {
                onComplete: () => {
                    this.loader = this.svg;
                },
                filter: 'blur(10px)',
                opacity: 0,
                fontSize: '3.5vw',
                duration: 0.5,
                ease: 'power3.in',
                overwrite: 'auto',
            });
            intro.to('.loadingio-spinner-ripple-64cwf99hv1r', {
                onComplete: () => {
                    this.endloading = true;
                },
                opacity: 0,
                duration: 0.5,
                ease: 'power3.in',
            });
            intro.to('.loaderi', {
                filter: 'blur(0px)',
                opacity: 1,
                fontSize: '1.5vw',
                duration: 0.5,
                ease: 'power3.inOut',
                overwrite: 'auto',
            });
            intro.to(
                '.loaderi',
                {
                    filter: 'blur(0px)',
                    opacity: 0,
                    duration: 0.5,
                    ease: 'power3.inOut',
                    overwrite: 'auto',
                },
                '+=0.5'
            );
            intro.play(0).then(() => {
                this.loading = false;
                const event = new Event('run');
                window.dispatchEvent(event);
            });
        },
    },
    data() {
        return {
            credit: false,
            menu: false,
            colors: '#ffffff',
            hideLabel: false,
            loading: true,
            endloading: false,
            loader: 'Loading',
            scrolled: false,
            dynamicBar: true,
            svg: ` <div style="justify-self: start; align-self: center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="77.654" height="77.654" viewBox="0 0 77.654 77.654" class="mt-1">
                            <g id="Groupe_2" data-name="Groupe 2" transform="translate(-166.184 -2.322)">
                                <g id="Groupe_1" data-name="Groupe 1" transform="translate(166.184 2.322)">
                                    <path
                                        id="path_1"
                                        data-name="Tracé 1"
                                        d="M205.011,79.976a38.827,38.827,0,1,1,38.827-38.827A38.871,38.871,0,0,1,205.011,79.976Zm0-72.623a33.8,33.8,0,1,0,33.8,33.8A33.834,33.834,0,0,0,205.011,7.353Z"
                                        transform="translate(-166.184 -2.322)"
                                        fill="#fff"
                                    ></path>
                                </g>
                                <path
                                    id="path_2"
                                    data-name="Tracé 2"
                                    d="M210.034,63.591a6.4,6.4,0,0,1,6.157-4.25c3.972,0,7.07,2.661,7.07,6.712V79.836c0,1.628.715,2.383,2.026,2.383.913,0,1.311-.556,1.668-1.669l5.481-16.96a6.348,6.348,0,0,1,6.157-4.25c3.972,0,7.07,2.661,7.07,6.712V86.787h-5.084V66.53c0-1.589-.715-2.383-2.066-2.383-.874,0-1.271.635-1.628,1.747l-5.561,16.921a6.159,6.159,0,0,1-6.116,4.29c-3.932,0-7.03-2.661-7.03-6.713V66.53c0-1.589-.715-2.383-2.065-2.383-.874,0-1.231.635-1.629,1.747l-7.308,20.893h-5.4Z"
                                    transform="translate(-20.019 -32.074)"
                                    fill="#fff"
                                ></path>
                            </g>
                        </svg>
                    </div>`,
            //
        };
    },
};
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100;0,400;0,500;0,700;0,900;1,100&display=swap');
.v-toolbar__content,
.v-toolbar__extension {
    align-items: start !important;
    padding: 0px !important;
}
.appBar-init {
    background-color: #00000000 !important;
}
.appBar-static {
    background-color: #00000077 !important;
}
.v-dialog {
    overflow: visible !important;
    background-color: #00000000;
    box-shadow: none !important;
}
.theme--light.v-timeline::before {
    background: rgba(0, 0, 0, 0) !important;
}
.v-timeline-item__divider {
    min-width: 65px;
}
.v-overlay {
    backdrop-filter: blur(1.5px) !important;
}
.loader {
    z-index: 999;
    position: fixed;
    width: 100vw !important;
    height: 100vh;
    background: radial-gradient(#222222, #000000);
    display: flex;
    justify-content: center;
    align-items: center;
}
.loaderi {
    color: rgb(255, 255, 255);
    font-size: 1.5vw;
    font-family: roboto;
    font-weight: 300;
    position: absolute;
    filter: blur(0px);
}
@keyframes ldio-ossuqae55ee {
    0% {
        top: 163.66px;
        left: 163.66px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 45.089999999999996px;
        left: 45.089999999999996px;
        width: 237.14px;
        height: 237.14px;
        opacity: 0;
    }
}
.ldio-ossuqae55ee div {
    position: absolute;
    border-width: 3.34px;
    border-style: solid;
    opacity: 1;
    border-radius: 50%;
    animation: ldio-ossuqae55ee 3.125s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.ldio-ossuqae55ee div:nth-child(1) {
    border-color: #ffffff;
    animation-delay: 0s;
}
.ldio-ossuqae55ee div:nth-child(2) {
    border-color: #009eeb;
    animation-delay: -1.5625s;
}
.loadingio-spinner-ripple-64cwf99hv1r {
    width: 334px;
    height: 334px;
    display: inline-block;
    overflow: hidden;
    background: none;
}
.ldio-ossuqae55ee {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
}
.ldio-ossuqae55ee div {
    box-sizing: content-box;
}
/* generated by https://loading.io/ */
/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgb(0, 0, 0);
}
.menu-card {
    background-color: #27272755 !important;
    backdrop-filter: blur(5px);
    display: flex !important;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
}
</style>
